.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
    font-family: 'Billo';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('./assets/billo.ttf') format('truetype');
}
