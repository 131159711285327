.slotMachineContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slotMachineSubContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid white;
    border-radius: 10px;
}

.slot {
    border: 2px solid white;
    border-radius: 10px;
    margin: 15px;
    padding: 10px;
    width: 160px;
    height: 160px;
}

.lever {
    border: 2px solid white;
    border-radius: 10px;
    margin: 15px;
    padding: 10px;
    width: 80px;
    height: 80px;
}

.linearWipe {
    background: linear-gradient(-45deg, #6355a4, #6355a4, #e89a3e, #e89a3e);
    background-size: 300%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 3vw;
    text-transform: uppercase;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animated_text 10s ease-in-out infinite;
}

@keyframes animated_text {
    0% {
        background-position: 0px 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0px 50%;
    }
}
